

















import { userStore } from '@/stores/user-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class AppBar extends Vue {
  userStore = userStore
}
